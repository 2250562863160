@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

.motd-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    overflow: hidden;
}

.arc-header {
    font-size: 24px;
    position: absolute;
    top: 20px;
    font-weight: 500;
    /* For the arched text, additional styling might be required or use a SVG/text path */
}

.frog img {
    max-width: 300px;
    cursor: pointer;
    user-select: none;
}

.message {
    font-size: 20px;
    text-align: center;
    margin: 20px 20px 0 20px;
}

.countdown {

}

.pet-again-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
}
