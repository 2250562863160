/* FoodRequestPage.css - Updated for cuteness and responsiveness */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

:root {
    --pastel-pink: #fde2e4;
    --pastel-green: #a8d5ba;
    --primary-color: #e29578;
    --light-pink: #ffe0f0;
    --light-green: #daf5e1;
    --dark-color: #22223b;
    --link-hover-color: #f67280;
    --button-bg-color: var(--pastel-green);
    --button-hover-bg-color: #96c5a6;
}

body {
    background-color: var(--light-pink);
    color: var(--dark-color);
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
}

.cute-heading {
    margin-top: 5vh;
    margin-bottom: 2rem;
    text-align: center;
}

.buttons-container {
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
    gap: 10px; /* Space between buttons */
    padding: 20px; /* Add some padding around the buttons for spacing */
}

.food-form button[type="submit"] {
    background-color: var(--button-bg-color);
    color: white;
    border: none;
    border-radius: 10px; /* Updated border-radius */
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 1rem;
}

.buttons-container button {
    flex: 1; /* Allows buttons to grow and take equal space */
    min-width: 250px; /* Minimum width for each button to ensure consistency */
    max-width: 250px; /* Maximum width to avoid overly wide buttons on larger screens */
    padding: 10px 0; /* Adjust padding for better vertical alignment and spacing */
    font-size: 1rem; /* Adjust font size as needed */
    text-align: center; /* Ensure text is centered within the button */
    border-radius: 10px; /* Your desired border-radius */
    background-color: var(--button-bg-color);
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.buttons-container button:hover {
    background-color: var(--button-hover-bg-color);
    transform: translateY(-2px); /* Optional: subtle hover effect */
}

.buttons-container button:hover {
    background-color: var(--button-hover-bg-color);
    transform: scale(1.05);
}

.food-form {
    position: relative;
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-bottom: 50px;
}

.food-form label {
    font-weight: bold;
    margin-top: 20px;
}

.food-form textarea {
    width: 100%;
    min-height: 100px; /* Adjusted for better visibility */
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    resize: vertical; /* Allows vertical resize */
}

/* Centering the Submit Button */
.food-form button[type="submit"] {
    display: block; /* Makes the button a block element */
    margin: 20px auto; /* Centers the button horizontally */
    background-color: var(--pastel-pink);
    border-radius: 10px;
}

/* Quiz Buttons Responsive Layout */
@media (min-width: 768px) { /* For medium screens and up */
    .quiz-container {
        flex-direction: row;
        justify-content: center;
    }
}

@media (max-width: 767px) { /* For small screens */
    .quiz-container {
        flex-direction: column;
        align-items: center;
    }
    .buttons-container button, .quiz-button {
        min-width: 50%; /* Makes buttons wider on smaller screens */
    }
}

@media (max-width: 576px) {
    .buttons-container {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
    }
    .buttons-container button {
        width: 250px; /* Buttons take full container width on small screens */
        margin-bottom: 10px; /* Add space between stacked buttons */
    }
}

.fade-enter, .fade-exit-active {
    opacity: 0;
    transform: scale(0.95);
}

.fade-enter-active, .fade-exit {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Increased gap for better visual separation */
    margin-bottom: 20px; /* Space below the quiz before possibly next content */
}

.quiz-question {
    font-size: 1.4rem; /* Make the question larger */
    color: var(--dark-color); /* Use a theme color or something that stands out */
    font-weight: 600; /* Make the font slightly bolder */
    margin-bottom: 1rem; /* Ensure there's space between the question and the buttons */
    text-align: center; /* Center-align the questions for readability */
}

.quiz-button {
    width: 200px;
    font-weight: 500; /* Not too bold, keeping it cute and readable */
    text-transform: uppercase; /* OPTIONAL: Adds a design choice, remove if it doesn't fit the "cuteness" */
    letter-spacing: 0.5px; /* Slightly increase spacing for a delicate touch */
    background-color: var(--pastel-green); /* Soft color for the button background */
    color: var(--pastel-pink); /* Contrast text color */
    border: 2px solid var(--light-pink); /* A soft border to stand out */
    padding: 12px 24px; /* Slightly larger padding for a plump button look */
    border-radius: 10px; /* Maintains the soft rounded edges */
    transition: background-color 0.3s, transform 0.2s, color 0.2s; /* Smooth transitions for interactions */
}

.quiz-button:hover {
    background-color: var(--pastel-pink); /* Interactive feedback on hover */
    color: var(--pastel-green); /* Change text color on hover for a cute surprise */
    border: 2px solid var(--pastel-green);
    transform: scale(1.1); /* Slightly enlarge buttons on hover */
}

.back-button-container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
}

.random-icon-container {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.random-icon {
    color: var(--dark-color); /* Adjust as needed */
}

.redo-quiz {
    cursor: pointer;
    color: var(--link-hover-color);
    text-align: center;
    margin-top: 20px;
}

.redo-quiz:hover {
    text-decoration: underline;
}

.back-button-icon {
    color: var(--pastel-green); /* Or any green color you prefer */
}