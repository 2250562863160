/* Reset box-sizing for all elements */
*,
*::before,
*::after {
  box-sizing: border-box; /* Include padding and borders in the width and height of all elements */
}

/* Use the 'root' pseudo-class to define custom properties (variables) */
:root {
  --pastel-pink: #ff4bb9;
  --pastel-green: #a8d5ba;
  --primary-color: #e29578;
  --light-pink: #ffe0f0;
  --light-green: #daf5e1;
  --dark-color: #22223b;
  --link-hover-color: #f67280;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Body styles */
body {
  background-color: var(--light-pink) !important; /* Remove !important if it's not necessary */
  color: var(--dark-color);
  font-family: 'Poppins', sans-serif;
  margin: 0; /* Remove default margin */
}

/* Navbar styles */
.navbar {
  /* Removed background color for a transparent navbar */
}

.navbar-collapse {
  justify-content: flex-end;
}

.navbar-toggler {
  background-color: var(--pastel-green) !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 224, 240, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

/* Navbar links styles */
.cute-nav-link {
  color: var(--dark-color) !important;
  font-weight: bold !important;
  transition: color 0.3s ease;
}

.cute-nav-link:hover, .navbar-brand:hover {
  color: var(--link-hover-color) !important;
}

/* Heading styles */
.cute-heading {
  color: var(--dark-color);
  font-weight: bold;
  font-size: 2.5rem;
}

/* Button styles */
.btn-primary {
  background-color: var(--primary-color);
  border: none;
}

.btn-primary:hover {
  background-color: darken(var(--primary-color), 5%);
}

.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: var(--light-pink);
}

.cute-footer {
  color: var(--pastel-pink);
  background-color: var(--pastel-green);
  text-align: center;
  width: 100%;
  /* Remove any margin or padding that might be causing extra space */
  margin: 0;
  padding: 1rem 0; /* Adjust padding as needed, but keep it uniform */
}

/* Link styles */
a {
  color: var(--primary-color);
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: none;
}

/* Main content styles to push footer to the bottom */
.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Make sure the footer is at the end of the main content */
/* If the content is shorter than the viewport, the footer will be at the bottom of the viewport */
/* If the content is taller, the footer will be at the bottom of the content */
.main-content > :last-child {
  margin-top: auto;
  /* If the footer still has extra space, consider removing margin-top: auto; and use padding on the footer's content instead */
}

.btn-custom-primary {
  background-color: var(--pastel-green) !important;
  border-color: var(--pastel-green) !important;
  color: var(--pastel-pink) !important;
}

.btn-custom-primary:hover {
  background-color: #96c5a6 !important; /* Manually chosen darker shade */
  border-color: #96c5a6 !important;
  color: var(--pastel-pink) !important;
}

.btn-custom-outline-primary {
  color: var(--pastel-green) !important;
  border-color: var(--pastel-green) !important;
}

.btn-custom-outline-primary:hover {
  color: var(--dark-color) !important;
  background-color: var(--pastel-green) !important;
  border-color: var(--pastel-green) !important;
}

@media (max-width: 991.98px) { /* Adjusts for Bootstrap's large navbar breakpoint */
  .navbar-nav {
    text-align: right;
  }
  .navbar-nav .nav-item {
    display: block;
  }
  .navbar-nav .nav-link {
    padding-right: .5rem; /* Adjust the padding as needed */
    padding-left: .5rem; /* Adjust the padding as needed */
  }
}

.back-button-icon {
  color: var(--pastel-green); /* Or any green color you prefer */
}