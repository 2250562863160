/* Define color variables */
:root {
    --pastel-pink: #fde2e4;
    --pastel-green: #a8d5ba;
}

/* Enhance the appearance of the page */
.container {
    text-align: center;
}

.header {
    margin-top: 20px;
    font-size: 2rem; /* Adjust size as needed */
}

.imgCustom{
    max-width: 90%; /* Limit size on smaller devices */
    border: 5px solid pink;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for depth */
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .imgCustom {
        max-width: 30%; /* Larger screens use less width */
    }
}

@media (max-width: 768px) {
    .header {
        margin-top: 70px;
    }
    .imgCustom {
        margin-top: -10px;
    }
}


.btn-custom {
    display: flex;
    justify-content: center;
    background-color: pink;
    color: white;
    border-radius: 20px; /* More rounded */
    border: none;
    padding: 10px 40px;
    font-size: 1rem;
}

.btn-custom:hover {
    background-color: var(--pastel-green);
    color: white;
}

.distanceMessage {
    font-size: 1.2rem;
    color: #333; /* Readable text color */
    margin-top: 20px;
}

.mapContainer {
    width: 100%;
    max-width: 90%; /* Make the map responsive */
    margin: 20px auto; /* Centering the map */
}

.centerContent {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    height: 100vh; /* Full height of the viewport */
}

.startImage {
    max-width: 100%; /* Ensure the image is responsive and fits within its container */
    border-radius: 10px; /* Optional: add rounded corners for a softer look */
}
