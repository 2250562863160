.valentine-page {
    text-align: center;
    padding: 20px;
}

.response-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: none;
    background-color: #ffccd5; /* Light pink */
    color: #fff;
    border-radius: 20px; /* Slightly rounded edges for a softer look */
    cursor: pointer;
    transition: transform 0.3s ease;
    font-size: 16px;
}

button:hover {
    transform: scale(1.1); /* Slightly enlarge buttons on hover */
}

.yes-button .fa-heart, .no-button .fa-times {
    margin-right: 8px; /* Space between icon and text */
}

.no-button {
    background-color: #f8d7da; /* A different shade for "No" button, or use the same as "Yes" */
}

.no-button:hover {
    background-color: #d3d3d3; /* Change on hover for "No" button */
}

/* Ensure the "No" button animation is smooth and the button is easily visible */
.no-button {
    position: relative; /* Use relative for smoother transitions within container */
}

/* Responsive buttons for mobile */
@media (max-width: 576px) {
    .response-buttons {
        flex-direction: column;
    }
}

/* Styles for the frog image */
.frog-image {
    max-width: 150px; /* Maximum size of the image */
    width: 50%; /* Responsive width, adjust as necessary */
    margin: 20px auto; /* Center the image horizontally */
}

/* Adjustments for larger screens */
@media (min-width: 801px) {
    .valentine-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Push content to top and bottom */
        height: 100vh;
    }

    .frog-image {
        max-width: 500px; /* Slightly larger on bigger screens */
    }
}

.icon-space {
    margin-right: 8px; /* Adjust the space as needed */
}

.back-button-icon {
    color: var(--pastel-green); /* Or any green color you prefer */
}