:root {
    --slider-gradient-start: #3acce3; /* Example start color */
    --slider-gradient-end: #FDFFB6; /* Example end color */
    --slider-thumb-color: #FFC0CB; /* Fallback thumb color, adjust as needed */
    --button-bg-color: #FFC0CB; /* Soft Pink */
    --peaceful-gradient-start: #a8deb5; /* Light green for peaceful */
    --peaceful-gradient-end: #fba7a7;

    --satiated-gradient-start: #fde2e4; /* Soft pink for Satiated */
    --satiated-gradient-end: #542d4a; /* Soft green for Hungry */

    --relaxed-gradient-start: #add8e6; /* Light blue for Relaxed */
    --relaxed-gradient-end: #ffcccb; /* Soft red for Stressed */

    --tired-energetic-start: #ff00c3;
    --tired-energetic-end: #7e50f2;

    --satisfied-gradient-start: #ffc400; /* Light pink for Satisfied */
    --satisfied-gradient-end: #ff4f4f;
    --button-text-color: white;
    --track-height: 8px; /* Height of the slider track */
}

.happy-sad-slider::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--slider-gradient-start) 0%, var(--slider-gradient-end) 100%);
}
.happy-sad-slider::-moz-range-track {
    background: linear-gradient(90deg, var(--slider-gradient-start) 0%, var(--slider-gradient-end) 100%);
}

.peaceful-angry-slider::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--peaceful-gradient-start) 0%, var(--peaceful-gradient-end) 100%);
}

.peaceful-angry-slider::-moz-range-track {
    background: linear-gradient(90deg, var(--peaceful-gradient-start) 0%, var(--peaceful-gradient-end) 100%);
}

.satiated-hungry-slider::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--satiated-gradient-start) 0%, var(--satiated-gradient-end) 100%);
}

.satiated-hungry-slider::-moz-range-track {
    background: linear-gradient(90deg, var(--satiated-gradient-start) 0%, var(--satiated-gradient-end) 100%);
}

.relaxed-stressed-slider::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--relaxed-gradient-start) 0%, var(--relaxed-gradient-end) 100%);
}

.tired-energetic-slider::-moz-range-track {
    background: linear-gradient(90deg, var(--tired-energetic-start) 0%, var(--tired-energetic-end) 100%);
}

.tired-energetic-slider::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--tired-energetic-start) 0%, var(--tired-energetic-end) 100%);
}

.relaxed-stressed-slider::-moz-range-track {
    background: linear-gradient(90deg, var(--relaxed-gradient-start) 0%, var(--relaxed-gradient-end) 100%);
}

.satisfied-horny-slider::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, var(--satisfied-gradient-start) 0%, var(--satisfied-gradient-end) 100%);
}

.satisfied-horny-slider::-moz-range-track {
    background: linear-gradient(90deg, var(--satisfied-gradient-start) 0%, var(--satisfied-gradient-end) 100%);
}


.mood-slider-container {
    padding: 20px;
    background-color: #FFF0F5; /* Light Lavender background */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 600px; /* Ensures the container doesn't stretch too wide on larger screens */
}

.mood-slider-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.mood-slider {
    width: 100%;
    margin-bottom: 50px;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: var(--track-height);
    background: transparent; /* Remove default background */
    cursor: pointer;
    outline: none;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: calc(var(--track-height)/2 * -1 - 2px); /* Center the thumb */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--slider-thumb-color);
    border: 2px solid #fff; /* White border for the thumb */
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--slider-thumb-color);
    border: 2px solid #fff;
    cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: var(--track-height);
    border-radius: 25px;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: var(--track-height);
    border-radius: 25px;
}

.mood-icon {
    font-size: 24px;
}

.submit-mood {
    display: block;
    width: 100%;
    text-align: center;
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-mood:hover {
    background-color: #FFB6C1; /* Lighter Pink */
}

.cute-heading {
    margin-top: 10vh; /* Adjust this value as needed to center the header vertically */
    margin-bottom: 2rem; /* Provides more space above the slider container */
}

.cute-heading-r {
    margin-top: 0vh; /* Adjust this value as needed to center the header vertically */
    margin-bottom: 2rem; /* Provides more space above the slider container */
}

body, .container {
    padding-bottom: 50px; /* Adds 50px padding at the bottom for mobile devices */
}

.back-button-icon {
    color: var(--pastel-green); /* Or any green color you prefer */
}