@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.kanban-board {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.kanban-column {
    background-color: #ffffff;
    flex: 1;
    min-width: 250px;
    max-width: 100%;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 20px;
}

.kanban-column h4 {
    color: #ffb4ee;
}

.list-group-item {
    background-color: var(--pastel-pink);
    color: #3f3f3f;
    font-weight: bold;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Adjust content spacing */
    height: 100%; /* Ensure it takes full height to push the author to the bottom */
}

.item-text {
    text-align: left; /* Align text to the left */
    margin-bottom: 10px;
    /* Remove align-self if not needed */
}

.item-details {
    display: flex;
    justify-content: space-between; /* Keep this for spacing between priority and author */
    align-items: center;
    width: 100%; /* Ensure it spans the full width of the container */
}

.priority-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.item-priority, .item-author {
    font-size: 0.85rem;
    font-weight: lighter;
    color: #666;
}

/* Add this to your GoalsPage.css */

.add-goal-btn {
    background-color: #33af65; /* A shade of green */
    color: white;
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.add-goal-btn:hover {
    background-color: #27ae60; /* A darker shade of green for hover effect */
}

/* Adjust the container padding to avoid overlap with the floating button */
.container.mt-3.bucket-list-page {
    position: relative;
    padding-top: 80px; /* Increase top padding */
}

.header1-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* This should align items vertically */
    padding: 20px 20px; /* Adjust padding as necessary */

}

.container1 .header1 {
    font-family: 'Pacifico', cursive;
    color: #ff84db; /* Adjust the color to fit your pink theme */
    margin: 0; /* Ensure no default margins */
    flex-grow: 1;
    text-align: left; /* Ensures the text aligns to the left */
}

.add-goal-btn {
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
}

/* Update container padding if needed */
.container.mt-3.bucket-list-page {
    padding-top: 20px; /* Adjust based on your layout */
}



@media (max-width: 768px) {
    .kanban-board {
        flex-direction: column;
    }
}
